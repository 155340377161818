<script>
import api from '@/helpers/apirest/api';
import { debounce } from 'lodash';
export default {
  name: 'SelectAutocompleteField',
  emits:['change'],
  props: {
    value: {
      type: [Number, String, Array],
    },
    initialOptions: {
      type: Array,
      default: () => []
    },
    validationName: Object,
    field: {
      type: String,
      default: 'id'
    },
    label: {
      type: String,
      default: 'name'
    },
    id: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
    multiple: {
      type: Boolean,
      default: false
    },
    url: {
      type: String,
      default: null
    },
    params: {
      type: Object,
      default: null
    },
    onChangePayload: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      options: this.initialOptions,
      loading: false
    };
  },
  computed: {
    selectedOption() {
      if (this.multiple) {
        return this.options.filter(option => (this.value) ? this.value.includes(option[this.field]) : false);
      }
      return this.options.find(option => option[this.field] === this.value) || null;
    }
  },
  methods: {
    updateValue(option) {
      if (this.multiple) {
        this.$emit('input', option ? option.map(option => option[this.field]) : []);
        this.$emit('change', option, this.onChangePayload);
      } else {
        this.$emit('input', option ? option[this.field] : null);
        this.$emit('change', option, this.onChangePayload);
      }
    },
    onOpen() {
      if (this.options.length === 0) {
        this.onSearch('', (status) => { this.loading = status; }, true);
      }
    },
    onSearch(search, loading, force = false) {
      if (search.length || force) {
        loading(true);
        this.search(loading, search, this);
      }
    },
    search: debounce(async(loading, search, vm) => {
      try {
        await vm.getData(search);
      } catch (error) {
        console.error('Error in search:', error);
      } finally {
        loading(false);
      }
    }, 350),
    async getData(search = '', extraParams = {}) {
      const res = await api.get({
        url: this.url,
        params: {
          ...this.params,
          filter: search,
          per_page: 30,
          ...extraParams
        }
      });
      this.options = res?.data?.data;
    }
  },
  watch: {
    value: {
      immediate: true,
      async handler(value) {
        if(value && this.options?.length === 0) {
          try {
            await this.getData('', {
              [this.field]: value
            });
          } catch (error) {
            console.error('Error in search:', error);
          }
        }
      }
    }
  },
};
</script>

<template>
  <v-select :options="options" :multiple="multiple" @search="onSearch" @open="onOpen" :loading="loading" :label="label"
    :value="selectedOption" @input="updateValue" :placeholder="placeholder">
    <template v-slot:no-options="{ search, searching }">
      <template v-if="searching">
        No se encontró resultados para <em>{{ search }}</em>.
      </template>
      <em v-else style="opacity: 0.5">Escribe para iniciar la búsqueda.</em>
    </template>
  </v-select>
</template>